<template>
  <div>
    <div class="header">
      <div class="account-title">信息选项</div>
      <el-form
          v-if="!isLogin"
          class="account-form"
          :inline="true"
          ref="form"
          :model="form"
          label-width="80px"
      >
        <el-form-item>
          <el-input v-model="form.phone" placeholder="账号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="form.code" placeholder="密码" show-password></el-input>
        </el-form-item>
        <el-form-item>
          <!--          <el-button type="default" :disabled="isTimer" @click="sendCode">-->
          <!--            <span v-if="!isTimer">发送验证码</span>-->
          <!--            <span class="timer" v-else>{{timer}}秒后发送</span>-->
          <!--          </el-button>-->
          <el-button type="default" @click="login">登录</el-button>
        </el-form-item>
      </el-form>
      <div v-else class="account-info">
        <el-row class="account-info-btn">
          <span>选择账户类型：</span>
          <el-button-group>
            <el-button size="small" @click="handleAccountType('仅策略')">策略</el-button>
            <el-button size="small" @click="handleAccountType('实盘账户')">实盘</el-button>
            <el-button size="small" @click="handleAccountType('产品')">产品</el-button>
          </el-button-group>
          <el-dialog title="请选择账户" :visible.sync="accountInputDialog">
            <el-table :data="accountMobileList" height="500" @row-click="handleAccountChoose">
              <el-table-column property="username" label="账号"></el-table-column>
              <el-table-column sortable :sort-method="(a,b) => sortPercentValue(a,b,'totalReturnRate')" property="totalReturnRate" label="累计收益率"></el-table-column>
              <el-table-column sortable :sort-method="(a,b) => sortPercentValue(a,b,'day30Return')" property="day30Return" label="近30日收益率"></el-table-column>
              <el-table-column sortable :sort-method="(a,b) => sortPercentValue(a,b,'day5ReturnRate')" property="day5ReturnRate" label="近5日收益率"></el-table-column>
              <el-table-column sortable :sort-method="(a,b) => sortPercentValue(a,b,'maxDrawdownRate')" property="maxDrawdownRate" label="最大回撤"></el-table-column>
            </el-table>
          </el-dialog>
<!--          <span style="margin-left: 2vw" v-show="!accountInputDisable">选择账户：</span>-->
<!--          <el-select-->
<!--              v-show="!accountInputDisable"-->
<!--              size="small"-->
<!--              v-model="accountMobile"-->
<!--              clearable-->
<!--              filterable-->
<!--              placeholder="请选择账户">-->
<!--            <el-option-->
<!--                v-for="item in accountMobileList"-->
<!--                :key="item.value"-->
<!--                :label="item.value"-->
<!--                :value="item.value">-->
<!--            </el-option>-->
<!--          </el-select>-->
          <span style="margin-left: 2vw" v-show="accountType==='实盘账户'">当前账户：{{username===''?'未选择':username}}</span>
          <span style="margin-left: 2vw" v-show="accountType==='仅策略'">选择策略：</span>
          <el-select
              v-show="accountType==='仅策略'"
              size="small"
              v-model="accountStrategy"
              clearable
              filterable
              placeholder="请选择策略">
            <el-option
                v-for="item in accountStrategyList"
                :key="item.strategyName"
                :label="item.strategyName"
                :value="item.strategyName">
            </el-option>
          </el-select>
          <span style="margin-left: 2vw" v-show="accountType==='产品'">选择产品：</span>
          <el-select
              v-show="accountType==='产品'"
              v-model="accountProductOpt"
              size="mini"
              collapse-tags
              clearable
              placeholder="请选择产品"
              @change="productChange"
          >
            <el-option
                v-for="item in accountProductOptions"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
          <span style="margin-left: 2vw" v-show="accountType==='产品' && accountProductOpt !== '' ">选择策略：</span>
          <el-cascader
              v-show="accountType==='产品' && accountProductOpt !== ''"
              size="mini"
              style="width: 260px"
              v-model="accountDivideOpt"
              :options="accountDivideOptions"
              :props="{ expandTrigger: 'hover',multiple: true }"
              clearable
              collapse-tags
          ></el-cascader>
          <el-button type="primary" v-show="accountType!=='实盘账户' && accountType!==''" size="small" icon="el-icon-search" style="margin-left: 1vw" @click="loadAccountData(false)">查询</el-button>
          <el-button size="small" style="float: right" @click="logout">登出</el-button>
        </el-row>
      </div>

    </div>
<!--    <div class="header" v-show="showAccountInfo">-->
<!--      <div class="account-title">{{headerTitle}}<span style="margin-left: 3vw">当前账户：{{username}}</span></div>-->
<!--      <div class="account-info">-->
<!--        <div class="account-info-item">-->
<!--          <div>累计收益率：{{ incomeStatisticData.totalReturnRate }}</div>-->
<!--          <div>夏普值：{{ incomeStatisticData.yearSharpRate }}</div>-->
<!--          &lt;!&ndash;          <div>逐笔平仓盈亏：-42735</div>&ndash;&gt;-->
<!--          &lt;!&ndash;          <div>风险度：19.73%</div>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="account-info-item">-->
<!--          <div>年化收益率：{{ incomeStatisticData.yearReturnRate }}</div>-->
<!--          <div>年化波动率：{{ incomeStatisticData.yearFlowRate }}</div>-->
<!--          &lt;!&ndash;          <div>逐笔平仓盈亏：-42735</div>&ndash;&gt;-->
<!--          &lt;!&ndash;          <div>轧差风险度：19.73%</div>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="account-info-item">-->
<!--          <div>最大回撤：{{ incomeStatisticData.maxDrawdownRate }}</div>-->
<!--          &lt;!&ndash;          <div>逐日持仓盈亏：17289375</div>&ndash;&gt;-->
<!--          &lt;!&ndash;          <div>非冲保证金：427350</div>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="account-info-item">-->
<!--          <div v-show="accountType!=='仅策略'">所选策略：<span style="color:red">{{currentStrategy}}</span></div>-->
<!--          &lt;!&ndash;          <div>当日盈亏：17289375</div>&ndash;&gt;-->
<!--          &lt;!&ndash;          <div>轧差保证金：4490430</div>&ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div ref="imageWrapper">
      <div class="main" v-show="isLogin">
        <div class="income-title">
          {{chartTitle}}
          <!--        <el-button class="income-list-btn" size="mini"-->
          <!--        >收益权益列表-->
          <!--        </el-button-->
          <!--        >-->
        </div>
        <div :class="showAccountInfo?'income-chart-content2':'income-chart-content'">
          <div class="income-chart-toolbar">
            <span>缩放</span>
            <el-radio-group v-model="dateScope" style="margin-left: 1vw" size="mini" @change="selectScope">
              <el-radio-button label="5">5日</el-radio-button>
              <el-radio-button label="30">30日</el-radio-button>
              <el-radio-button label="90">90日</el-radio-button>
              <el-radio-button label="365">一年</el-radio-button>
              <el-radio-button label="all">全部</el-radio-button>
            </el-radio-group>
            <span style="margin-left: 1vw">起始日期：</span>
            <el-date-picker
                @change="statisticRangeChange"
                size="small"
                v-model="statisticRange.start"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
            <span style="margin-left: 1vw">结束日期：</span>
            <el-date-picker
                @change="statisticRangeChange"
                size="small"
                value-format="yyyy-MM-dd"
                v-model="statisticRange.end"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
            <span style="margin-left: 1vw">统计维度：</span>
            <el-switch
                v-model="dimension"
                active-text="周"
                active-value="week"
                inactive-text="天"
                inactive-value="day"
            >
            </el-switch>
            <span style="margin-left: 1vw">大盘选择：</span>
            <el-select
                v-model="benchmarkOpt"
                size="mini"
                multiple
                clearable
                collapse-tags
                placeholder="请选择大盘"
                @change="benchmarkChange"
            >
              <el-option
                  v-for="item in benchmarkOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <el-button type="primary" size="small" icon="el-icon-search" style="margin-left: 1vw" @click="loadAccountData(false)">查询</el-button>
            <el-button type="success" size="small" icon="el-icon-download" style="margin-left: 1vw" @click="exportReport">导出历史净值</el-button>
            <el-button type="success" size="small" icon="el-icon-download" style="margin-left: 1vw" @click="exportImg">导出图片</el-button>
          </div>
          <v-chart :options="option" autoresize ref="vchart"/>
        </div>
      </div>

      <div class="table-one" v-show="isLogin">
        <div class="income-title">描述性统计</div>
        <el-table
            :data="descriptiveStatisticsData"
            style="width: 100%;padding: 0 0.5vw">
          <el-table-column
              prop="name"
              label="名称">
          </el-table-column>
<!--          <el-table-column-->
<!--              v-show="accountType==='产品'"-->
<!--              prop="subName"-->
<!--              label="子账户名称">-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--              v-show="accountType==='产品'"-->
<!--              prop="divideName"-->
<!--              label="子策略名称">-->
<!--          </el-table-column>-->
          <el-table-column
              prop="startDate"
              label="开始时间">
          </el-table-column>
          <el-table-column
              prop="nowDate"
              label="最新时间">
          </el-table-column>
          <el-table-column
              prop="netValue"
              label="期末净值">
          </el-table-column>
          <el-table-column
              prop="totalReturnRate"
              label="累计收益率">
          </el-table-column>
          <el-table-column
              prop="yearReturnRate"
              label="年化收益率">
          </el-table-column>
          <el-table-column
              prop="maxDrawdownRate"
              label="最大回撤率">
          </el-table-column>
          <el-table-column
              prop="yearFlowRate"
              label="年化波动率">
          </el-table-column>
          <el-table-column
              prop="yearSharpRate"
              label="夏普比率">
          </el-table-column>
          <el-table-column
              prop="kamaRate"
              label="卡玛比率">
          </el-table-column>
        </el-table>
      </div>
    </div>


    <div class="table-one" v-show="isLogin">
      <div class="income-title">风险敞口</div>
      <div class="income-chart-toolbar">
        <span>选择日期：</span>
        <el-date-picker
            @change="riskExposureDateChange"
            size="small"
            v-model="riskExposureDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
        </el-date-picker>
      </div>
      <el-table
          :data="riskExposureData"
          style="width: 100%;padding: 0 0.5vw">
        <el-table-column
            prop="productId"
            label="产品ID">
        </el-table-column>
        <el-table-column
            prop="productName"
            label="产品名称">
        </el-table-column>
        <el-table-column
            prop="subId"
            label="子账户ID">
        </el-table-column>
        <el-table-column
            prop="subName"
            label="子账户名称">
        </el-table-column>
        <el-table-column
            prop="divideId"
            label="子策略ID">
        </el-table-column>
        <el-table-column
            prop="divideName"
            label="子策略名称">
        </el-table-column>
        <el-table-column
            prop="fundManagement"
            label="基金经理">
        </el-table-column>
<!--        <el-table-column-->
<!--            prop="asOfDate"-->
<!--            label="日期">-->
<!--        </el-table-column>-->
        <el-table-column
            prop="strategyAmountVol"
            label="风险敞口">
        </el-table-column>
        <el-table-column
            prop="averageExposureAmount"
            label="平均风险敞口">
        </el-table-column>
        <el-table-column
            prop="extremeExposureAmount"
            label="极端风险敞口">
        </el-table-column>
        <el-table-column
            prop="annualizeStrategyAmountVol"
            label="年化风险敞口">
        </el-table-column>
        <el-table-column
            prop="annualizeAverageExposureAmount"
            label="年化平均风险敞口">
        </el-table-column>
        <el-table-column
            prop="annualizeExtremeExposureAmount"
            label="年化极端风险敞口">
        </el-table-column>


      </el-table>
    </div>
    <div class="table-one" v-show="isLogin">
      <div class="income-title">其他数据</div>
      <div class="income-chart-toolbar">
        <span>选择报表：</span>
        <el-select v-model="selectedDisplayTable" placeholder="请选择" size="small" @change="selectDisplay">
          <el-option
              v-for="item in displayTableOpt"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <span style="margin-left: 1vw" >选择日期：</span>
        <el-date-picker
            @change="selectDisplay"
            size="small"
            v-model="displayDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
        </el-date-picker>
      </div>
      <el-table
          :data="positionsData"
          v-show="selectedDisplayTable==='position' && (accountType === '产品' || accountType === '仅策略')"
          style="width: 100%;padding: 0 0.5vw">
        <el-table-column
            prop="productId"
            label="产品ID">
        </el-table-column>
        <el-table-column
            prop="productName"
            label="产品名称">
        </el-table-column>
        <el-table-column
            prop="subId"
            label="子账户ID">
        </el-table-column>
        <el-table-column
            prop="subName"
            label="子账户名称">
        </el-table-column>
        <el-table-column
            prop="divideId"
            label="子策略ID">
        </el-table-column>
        <el-table-column
            prop="divideName"
            label="子策略名称">
        </el-table-column>
        <el-table-column
            prop="fundManagement"
            label="基金经理">
        </el-table-column>
        <el-table-column
            prop="ticker"
            label="股票代码">
        </el-table-column>
        <el-table-column
            prop="tickerName"
            label="股票名称">
        </el-table-column>
        <el-table-column
            prop="marketCap"
            label="股票市值(亿元)">
        </el-table-column>
        <el-table-column
            prop="costPrice"
            label="成本价">
        </el-table-column>
        <el-table-column
            prop="industryName"
            label="行业名称">
        </el-table-column>
        <el-table-column
            prop="number"
            label="数量">
        </el-table-column>
        <el-table-column
            prop="lastPrice"
            label="昨日价格">
        </el-table-column>
        <el-table-column
            prop="nowPrice"
            label="今日价格">
        </el-table-column>
        <el-table-column
            prop="size"
            label="合约乘数">
        </el-table-column>
      </el-table>
      <el-table
          :data="tradeData"
          v-show="selectedDisplayTable==='trade' && (accountType === '产品' || accountType === '仅策略')"
          max-height="800"
          style="width: 100%;padding: 0 0.5vw">
        <el-table-column
            prop="productId"
            label="产品ID" fixed>
        </el-table-column>
        <el-table-column
            prop="productName"
            label="产品名称" fixed>
        </el-table-column>
        <el-table-column
            prop="subId"
            label="子账户ID">
        </el-table-column>
        <el-table-column
            prop="subName"
            label="子账户名称">
        </el-table-column>
        <el-table-column
            prop="divideName"
            label="子策略名称">
        </el-table-column>
        <el-table-column
            prop="divideName"
            label="子策略名称">
        </el-table-column>
        <el-table-column
            prop="fundManagement"
            label="基金经理">
        </el-table-column>
        <el-table-column
            prop="ticker"
            label="股票代码">
        </el-table-column>
        <el-table-column
            prop="tickerName"
            label="股票名称">
        </el-table-column>
        <el-table-column
            prop="openEntrustDatetime"
            label="开仓委托时间">
        </el-table-column>
        <el-table-column
            prop="openEntrustPrice"
            label="开仓委托价格">
        </el-table-column>
        <el-table-column
            prop="openEntrustNum"
            label="开仓委托数量">
        </el-table-column>
        <el-table-column
            prop="openEntrustOrderType"
            label="开仓委托类型">
        </el-table-column>
        <el-table-column
            prop="openTradeDatetime"
            label="开仓交易时间">
        </el-table-column>
        <el-table-column
            prop="openTradePrice"
            label="开仓交易价格">
        </el-table-column>
        <el-table-column
            prop="openTradeNum"
            label="开仓交易数量">
        </el-table-column>
        <el-table-column
            prop="openTradeCommission"
            label="开仓交易手续费">
        </el-table-column>
        <el-table-column
            prop="openSlippage"
            label="开仓交易滑点">
        </el-table-column>
        <el-table-column
            prop="closeEntrustDatetime"
            label="平仓委托时间">
        </el-table-column>
        <el-table-column
            prop="closeEntrustPrice"
            label="平仓委托价格">
        </el-table-column>
        <el-table-column
            prop="closeEntrustNum"
            label="平仓委托数量">
        </el-table-column>
        <el-table-column
            prop="closeEntrustOrderType"
            label="平仓委托类型">
        </el-table-column>
        <el-table-column
            prop="closeTradeDatetime"
            label="平仓交易时间">
        </el-table-column>
        <el-table-column
            prop="closeTradePrice"
            label="平仓交易价格">
        </el-table-column>
        <el-table-column
            prop="closeTradeNum"
            label="平仓交易数量">
        </el-table-column>
        <el-table-column
            prop="closeTradeCommission"
            label="平仓交易手续费">
        </el-table-column>
        <el-table-column
            prop="closeSlippage"
            label="平仓交易滑点">
        </el-table-column>
        <el-table-column
            prop="size"
            label="合约乘数">
        </el-table-column>
      </el-table>
      <el-table
          :data="positionsData"
          v-show="selectedDisplayTable==='position' && (accountType === '实盘账户')"
          style="width: 100%;padding: 0 0.5vw">
        <el-table-column
            prop="accountName"
            label="名称">
        </el-table-column>
        <el-table-column
            prop="ticker"
            label="证券代码">
        </el-table-column>
        <el-table-column
            prop="tickerName"
            label="证券名称">
        </el-table-column>
        <el-table-column
            prop="numberTransactions"
            label="股票余额">
        </el-table-column>
        <el-table-column
            prop="cash"
            label="可用股份">
        </el-table-column>
        <el-table-column
            prop="costPrice"
            label="成本价">
        </el-table-column>
        <el-table-column
            prop="marketPrice"
            label="市价">
        </el-table-column>
        <el-table-column
            prop="marketValue"
            label="市值">
        </el-table-column>
        <el-table-column
            prop="profitAndLoss"
            label="盈亏">
        </el-table-column>
        <el-table-column
            prop="profitLossRatio"
            label="盈亏比例">
        </el-table-column>
        <el-table-column
            prop="frozenQuantity"
            label="冻结数量">
        </el-table-column>
      </el-table>
      <el-table
          :data="tradeData"
          v-show="selectedDisplayTable==='trade' && (accountType === '实盘账户')"
          max-height="800"
          style="width: 100%;padding: 0 0.5vw">
        <el-table-column
            prop="accountName"
            label="名称">
        </el-table-column>
        <el-table-column
            prop="ticker"
            label="股票代码">
        </el-table-column>
        <el-table-column
            prop="tickerName"
            label="股票名称">
        </el-table-column>
        <el-table-column
            prop="operate"
            label="方向">
        </el-table-column>
        <el-table-column
            prop="numberTransactions"
            label="成交数量">
        </el-table-column>
        <el-table-column
            prop="averagePrice"
            label="成交均价">
        </el-table-column>
      </el-table>
    </div>
    <div class="table-one" v-show="isLogin">
      <div class="income-title">业绩归因</div>
      <div class="income-chart-toolbar">
        <span>选择报表：</span>
        <el-select v-model="selectedAttributionTable" placeholder="请选择" size="small" @change="selectAttribution">
          <el-option
              v-for="item in attributionTableOpt"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <span style="margin-left: 1vw" v-show="selectedAttributionTable==='attribution_industry'" >选择维度：</span>
        <el-switch
            v-model="attributionDimension"
            v-show="selectedAttributionTable==='attribution_industry'"
            active-text="周"
            active-value="week"
            inactive-text="天"
            inactive-value="day"
            @change="selectAttribution"
        >
        </el-switch>
      <el-table
          :data="attributionData"
          v-loading="attribution_loading"
          v-show="selectedAttributionTable==='attribution_date'"
          style="width: 100%;">
        <el-table-column
            prop="As_Of_Date"
            label="日期">
        </el-table-column>
        <el-table-column
            prop="selection"
            label="个股选择收益">
        </el-table-column>
        <el-table-column
            prop="allocation"
            label="资产配置收益">
        </el-table-column>
        <el-table-column
            prop="interaction"
            label="交叉收益">
        </el-table-column>
        <el-table-column
            prop="buy_impact"
            label="买入影响">
        </el-table-column>
        <el-table-column
            prop="sell_impact"
            label="卖出影响">
        </el-table-column>
        <el-table-column
            prop="commission"
            label="手续费">
        </el-table-column>
        <el-table-column
            prop="Fund_Management"
            label="基金经理">
        </el-table-column>
      </el-table>
      <el-table
            :data="attributionData"
            v-loading="attribution_loading"
            v-show="selectedAttributionTable==='attribution_industry'"
            style="width: 100%;">
          <el-table-column
              prop="As_Of_Date"
              label="日期">
          </el-table-column>
          <el-table-column
              prop="Industry_name"
              label="行业名称">
          </el-table-column>
          <el-table-column
              prop="w_t-1"
              label="行业权重">
          </el-table-column>
          <el-table-column
              prop="r_t"
              label="行业回报">
          </el-table-column>
          <el-table-column
              prop="selection"
              label="个股选择收益">
          </el-table-column>
          <el-table-column
              prop="allocation"
              label="资产配置收益">
          </el-table-column>
          <el-table-column
              prop="interaction"
              label="交叉收益">
          </el-table-column>
          <el-table-column
              prop="buy_impact"
              label="买入影响">
          </el-table-column>
        <el-table-column
              prop="sell_impact"
              label="卖出影响">
          </el-table-column>
        <el-table-column
              prop="commission"
              label="手续费">
          </el-table-column>
        <el-table-column
              prop="market_weight_sum"
              label="权重汇总">
          </el-table-column>
        <el-table-column
              prop="market_return_sum"
              label="回报汇总">
          </el-table-column>
        <el-table-column
              prop="Fund_Management"
              label="基金经理">
          </el-table-column>
        <el-table-column
              prop="Frequency"
              label="频率">
        </el-table-column>
      </el-table>
      <el-table
            :data="attributionData"
            v-loading="attribution_loading"
            v-show="selectedAttributionTable==='attribution_industry_all'"
            style="width: 100%;">
          <el-table-column
              prop="As_Of_Date"
              label="日期">
          </el-table-column>
          <el-table-column
              prop="Industry_name"
              label="行业名称">
          </el-table-column>
          <el-table-column
              prop="w_t-1"
              label="行业权重">
          </el-table-column>
          <el-table-column
              prop="r_t"
              label="行业回报">
          </el-table-column>
          <el-table-column
              prop="selection"
              label="个股选择收益">
          </el-table-column>
          <el-table-column
              prop="allocation"
              label="资产配置收益">
          </el-table-column>
          <el-table-column
              prop="interaction"
              label="交叉收益">
          </el-table-column>
          <el-table-column
              prop="buy_impact"
              label="买入影响">
          </el-table-column>
          <el-table-column
              prop="sell_impact"
              label="卖出影响">
          </el-table-column>
          <el-table-column
              prop="commission"
              label="手续费">
          </el-table-column>
          <el-table-column
              prop="market_weight_sum"
              label="权重汇总">
          </el-table-column>
          <el-table-column
              prop="market_return_sum"
              label="回报汇总">
          </el-table-column>
          <el-table-column
              prop="Fund_Management"
              label="基金经理">
          </el-table-column>
        </el-table>
      <el-pagination
          small
          background
          hide-on-single-page
          layout="prev, pager, next, sizes"
          style="padding: 0 0.5vw"
          @current-change="selectAttributionPageChange"
          @size-change="selectAttributionSizeChange"
          :page-sizes="[10,30,50]"
          :page-size="attributionPageInfo.pageSize"
          :current-page="attributionPageInfo.currentPage"
          :page-count="attributionPageInfo.pageCount"
      >
      </el-pagination>
    </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import * as request from '@/api';
import VChart from "vue-echarts";
import xlsx from 'xlsx'
import html2canvas from "html2canvas"

export default {
  components: {
    VChart
  },
  name: "Home",
  methods: {
    logout() {
      this.isLogin = false;
      request.logout()
    },
    login() {
      const username = this.form.phone;
      if (!username){
        this.$message.error("请输入用户名");
        return;
      }
      const pass = this.form.code;
      if (!pass) {
        this.$message.error("请输入密码");
        return;
      }
      request.login(username, pass).then(() => {
        this.$message.success("登陆成功")
        this.isLogin = true
        this.loadAccountData(true);
        request.strategyList().then(value => {
          this.accountStrategyList = value.map(v=>{
            v.value = v.strategyName
            return v
          })
        })
        this.handleAccountType('')
        this.loadDisplayData();
        // this.selectDisplay();
      }).catch(() => {
        this.$message.error('登陆出错')
        this.isLogin = false
      });
    },
    handleAccountChoose(row) {
      this.accountMobile = row.username;
      if (this.accountMobile !== ''){
        this.username = this.accountMobile;
      }
      this.statisticRange.start = ''
      this.statisticRange.end = ''
      this.loadAccountData(false)
      this.accountInputDialog = false
    },
    handleAccountType: async function(accountType){
      this.accountType = accountType;
      this.accountMobile = ''
      this.username=this.accountMobile
      this.accountStrategy = ''
      if (this.accountType === '实盘账户') {
        this.accountInputDialog = true
        let accountList = await request.accountList(this.accountType);
        this.accountMobileList = accountList;
      }
      this.positionsData = []
      this.tradeData = []
    },
    accountMobileQuery(queryString, cb){
      const list = this.accountMobileList;
      const results = queryString ? list.filter(this.createFilter(queryString)) : list;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    accountStrategyQuery(queryString, cb){
      const list = this.accountStrategyList;
      const results = queryString ? list.filter(this.createFilter(queryString)) : list;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (list) => {
        return (list.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    async sendCode() {
      this.isTimer = true;
      const phone = this.vfphonenumber();
      if (!phone) {
        this.$message.error("请检查手机号是否正确");
        this.isTimer = false;
        return;
      }
      const isusedres = await request.usedphonenumber(this.phone)
      const isused = isusedres.data
      if (isused === 'false') {
        this.$message.error("该手机号未注册");
        this.isTimer = false;
        return;
      }
      request.loginsmscode(this.form.phone);
      this.timeDiDa();
      let tt = setTimeout(() => {
        this.$message.info('验证码已发送');
        clearInterval(tt);
      }, 20);
    },
    timeDiDa() {
      this.timerId = setInterval(() => {
        this.isTimer = true;
        --this.timer
        if (this.timer < 0) {
          this.isTimer = false;
          clearInterval(this.timerId)
          this.timer = 60;
        }
      }, 1000)
    },
    vfphonenumber() {
      const pattern = /^1[3456789]\d{9}$/
      return pattern.test(this.form.phone);
    },
    selectScope() {
      this.statisticRange.start = ''
      this.statisticRange.end = ''
      this.loadAccountData(this.accountMobile==="");
    },
    statisticRangeChange() {
      this.dateScope = ''
    },
    sortPercentValue(a,b,prop){
      return Number.parseInt(a[prop].substring(0,a[prop].length-1)) - Number.parseInt(b[prop].substring(0,b[prop].length-1))
    },
    async loadAccountData(isIndex) {
      this.$refs.vchart.clear()
      // const defaultSeriesName = ['客户收益','超额收益']
      if (this.accountType === '实盘账户' || this.accountType === '') {
        let {
          benchmarkNames,
          benchmarks,
          historyIncomes,
          exceedIncomes,
          userStrategyName
        } = await request.incomeVisual(this.accountMobile, this.dateScope, this.statisticRange.start, this.statisticRange.end, this.dimension, this.accountType === '模拟账户');
        this.currentStrategy = userStrategyName
        let series = [];
        for (let benchmark in benchmarks) {
          if (Object.prototype.hasOwnProperty.call(benchmarks, benchmark)) {
            series.push({
              name: benchmark,
              type: 'line',
              symbol: 'none',
              smooth:false,
              itemStyle:{
                normal:{
                  lineStyle:{
                    width:2,
                    type:'dotted'
                  }
                }
              },
              // areaStyle: {},
              // stack: '总量',
              data: benchmarks[benchmark].map(b => b.rate + 1)
            })
          }
        }
        if (!isIndex && this.accountMobile !== '') {
          series.push({
            name: '客户收益',
            type: 'line',
            color: "#EE6666",
            symbol: 'none',
            data: historyIncomes.map(h => h.totalReturn + 1)
          })
          series.push({
            name: '超额收益',
            type: 'line',
            color: "#ac9dfa",
            symbol: 'none',
            data: exceedIncomes.map(e => e + 1)
          })
          benchmarkNames.unshift('超额收益');
          benchmarkNames.unshift('客户收益');
          let start = historyIncomes[0].date
          let end = historyIncomes[historyIncomes.length-1].date
          this.incomeStatisticData = await request.incomeStatistic(this.accountMobile, start, end, this.dateScope, this.accountType === '模拟账户');
          this.incomeStatisticData.name = this.username;
          this.incomeStatisticData.startDate = start
          this.incomeStatisticData.nowDate = end
          this.incomeStatisticData.netValue = (parseFloat(this.incomeStatisticData.totalReturnRate) / 100 + 1).toFixed(2);
          this.incomeStatisticData.yearSharpRate = (parseFloat(this.incomeStatisticData.yearSharpRate) / 100).toFixed(2);
          this.incomeStatisticData.kamaRate = (parseFloat(this.incomeStatisticData.yearReturnRate) / parseFloat(this.incomeStatisticData.maxDrawdownRate)).toFixed(2);
          this.descriptiveStatisticsData = [this.incomeStatisticData];
        }
        const selectedSeries = ['超额收益', '客户收益', ...this.benchmarkOpt];
        this.option.legend.data = benchmarkNames.filter(l => selectedSeries.indexOf(l) >= 0);
        this.option.series = series.filter(s => selectedSeries.indexOf(s.name) >= 0);
        this.option.xAxis.data = benchmarks[Object.keys(benchmarks)[0]].map(h => h.date)
        // console.log("option",this.option)
      }
      else if(this.accountType === '仅策略') {
        const strategyList = this.accountStrategyList.filter(value => value.strategyName===this.accountStrategy);
        // console.log(strategyList)
        if (strategyList.length===0) {
          this.$message.info("请选择策略");
        } else {
          const strategyName = strategyList[0].strategyName;
          const strategyId = strategyList[0].strategyId;
          const classId = strategyList[0].classId;
          let start = this.statisticRange.start
          let end = this.statisticRange.end
          const scope = this.dateScope;
          let {
            benchmarkNames,
            benchmarks,
            historyIncomes
          } = await request.strategyInfo(strategyId, classId, scope, start, end, this.dimension);
          let series = [];
          for (let benchmark in benchmarks) {
            if (Object.prototype.hasOwnProperty.call(benchmarks, benchmark)) {
              series.push({
                name: benchmark,
                type: 'line',
                symbol: 'none',
                smooth:false,
                itemStyle:{
                  normal:{
                    lineStyle:{
                      width:2,
                      type:'dotted'
                    }
                  }
                },
                // areaStyle: {},
                // stack: '总量',
                data: benchmarks[benchmark].map(b => b.rate + 1)
              })
            }
          }
          series.push({
            name: `${strategyName}收益`,
            type: 'line',
            color: "#EE6666",
            symbol: 'none',
            data: historyIncomes.map(h => h.totalReturn + 1)
          })
          benchmarkNames.unshift(`${strategyName}收益`);
          const selectedSeries = [`${strategyName}收益`,...this.benchmarkOpt];
          this.option.legend.data = benchmarkNames.filter(l => selectedSeries.indexOf(l) >= 0);
          this.option.series = series.filter(s => selectedSeries.indexOf(s.name) >= 0);
          // this.option.legend.data = benchmarkNames;
          // this.option.series = series;
          this.option.xAxis.data = benchmarks[Object.keys(benchmarks)[0]].map(h => h.date)
          this.incomeStatisticData = await request.strategyStatistic(strategyId, classId, start, end, scope);
          this.incomeStatisticData.name = strategyName;
          start = historyIncomes[0].date
          end = historyIncomes[historyIncomes.length-1].date
          this.incomeStatisticData.startDate = start
          this.incomeStatisticData.nowDate = end
          this.incomeStatisticData.netValue = (parseFloat(this.incomeStatisticData.totalReturnRate) / 100 + 1).toFixed(2);
          this.incomeStatisticData.yearSharpRate = (parseFloat(this.incomeStatisticData.yearSharpRate) / 100).toFixed(2);
          this.incomeStatisticData.kamaRate = (parseFloat(this.incomeStatisticData.yearReturnRate) / parseFloat(this.incomeStatisticData.maxDrawdownRate)).toFixed(2);
          this.descriptiveStatisticsData = [this.incomeStatisticData];
        }

      }
      else if(this.accountType === '产品') {
        if (this.accountDivideOpt.length === 0) {
          this.$message.info("请选择产品");
        } else {
          const productInfo = this.getProductInfo();
          const productName = productInfo.productName;
          let start = this.statisticRange.start;
          let end = this.statisticRange.end;
          const scope = this.dateScope;
          let {
            benchmarkNames,
            benchmarks,
            historyIncomes
          } = await request.productInfo({
            ...productInfo, scope, start, end
          });
          let series = [];
          for (let benchmark in benchmarks) {
            if (Object.prototype.hasOwnProperty.call(benchmarks, benchmark)) {
              series.push({
                name: benchmark,
                type: 'line',
                symbol: 'none',
                smooth: false,
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 2,
                      type: 'dotted'
                    }
                  }
                },
                // areaStyle: {},
                // stack: '总量',
                data: benchmarks[benchmark].map(b => b.rate + 1)
              })
            }
          }
          series.push({
            name: `${productName}收益`,
            type: 'line',
            color: "#EE6666",
            symbol: 'none',
            data: historyIncomes.map(h => h.totalReturn + 1)
          });
          benchmarkNames.unshift(`${productName}收益`);
          const selectedSeries = [`${productName}收益`, ...this.benchmarkOpt];
          this.option.legend.data = benchmarkNames.filter(l => selectedSeries.indexOf(l) >= 0);
          this.option.series = series.filter(s => selectedSeries.indexOf(s.name) >= 0);
          // this.option.legend.data = benchmarkNames;
          // this.option.series = series;
          this.option.xAxis.data = benchmarks[Object.keys(benchmarks)[0]].map(h => h.date);
          this.incomeStatisticData = await request.productStatistic({
            ...productInfo, scope, start, end
          });
          this.incomeStatisticData.name = productName;
          start = historyIncomes[0].date;
          end = historyIncomes[historyIncomes.length - 1].date;
          this.incomeStatisticData.startDate = start;
          this.incomeStatisticData.nowDate = end;
          this.incomeStatisticData.netValue = (parseFloat(this.incomeStatisticData.totalReturnRate) / 100 + 1).toFixed(2);
          this.incomeStatisticData.yearSharpRate = (parseFloat(this.incomeStatisticData.yearSharpRate) / 100).toFixed(2);
          this.incomeStatisticData.kamaRate = (parseFloat(this.incomeStatisticData.yearReturnRate) / parseFloat(this.incomeStatisticData.maxDrawdownRate)).toFixed(2);
          // this.incomeStatisticData.subName = subName
          // this.incomeStatisticData.divideName = divideName
          this.descriptiveStatisticsData = [this.incomeStatisticData];
          this.selectDisplay();
          this.selectRiskExposure();
        }
      }
    },
    getProductInfo() {
      const sceneTypeMap = {
        "实盘账户": 1,
        "仅策略": 2,
        "产品": 2
      };
      return {
        productName: this.accountProductOpt,
        divideList: this.accountDivideOpt.map(d => d[1]),
        dimension: this.dimension,
        sceneType: sceneTypeMap[this.accountType],
        accountName: this.username
      };
    },
    exportImg() {
      html2canvas(this.$refs.imageWrapper).then(canvas => {
        // this.imgUrl = canvas.toDataURL("image/png");
        let link = document.createElement("a");
        link.href = canvas.toDataURL();//下载链接
        link.setAttribute("download","导出结果.png");
        link.style.display = "none";//a标签隐藏
        document.body.appendChild(link);
        link.click();
        // if (this.imgUrl !== "") {
        //   this.dialogTableVisible = true;
        // }
      });
    },
    async exportReport(){
      const series = this.option.series
      const xAxis = this.option.xAxis.data
      let exportData = []
      for (let i = 0; i < xAxis.length; i++) {
        let exportObj = {}
        exportObj['日期']=xAxis[i]
        series.forEach(s=>{
          exportObj[`${s.name}`] = s.data[i];
        })
        exportData.push(exportObj)
      }
      const workSheet = xlsx.utils.json_to_sheet(exportData)
      const workBoot = xlsx.utils.book_new()
      xlsx.utils.book_append_sheet(workBoot,workSheet,'Sheet1')
      xlsx.writeFile(workBoot,'历史净值.xlsx')
    },
    async loadRealAccount() {
      await request.realAccountStrategies()
    },
    async loadDisplayData() {
      // this.riskExposureData = await request.riskExposures(this.displayDate,this.getProductInfo());
      this.accountProductOptions = await request.products();
    },
    selectRiskExposure(){
      request.riskExposures(this.riskExposureDate, this.getProductInfo())
        .then(res =>{
          this.riskExposureData = res;
        })
    },
    selectDisplay() {
      const displayName = this.selectedDisplayTable
      const productInfo = this.getProductInfo();
      switch (displayName) {
        case 'position':
          request.positions(this.displayDate, productInfo)
              .then(res => {
                this.positionsData = res;
              });
          break;
        case 'trade':
          request.trades(this.displayDate, productInfo)
              .then(res => {
                this.tradeData = res;
              });
          break;
      }
    },
    selectAttributionSizeChange(pageSize) {
      this.attributionPageInfo.currentPage = 1;
      this.attributionPageInfo.pageSize = pageSize;
      this.loadAttributionData();
    },
    selectAttributionPageChange(pageNum) {
      this.attributionPageInfo.currentPage = pageNum;
      this.loadAttributionData();
    },
    selectAttribution() {
      this.attributionPageInfo.currentPage = 1;
      this.attributionPageInfo.pageSize = 10;
      this.loadAttributionData()
    },
    loadAttributionData() {
      this.attribution_loading = true;
      const attributionName = this.selectedAttributionTable
      // const productInfo = this.getProductInfo();
      switch (attributionName) {
        case 'attribution_date':
          request.attributionDate(this.attributionPageInfo.currentPage,this.attributionPageInfo.pageSize)
              .then(res => {
                this.attributionData = res.data;
                this.attributionPageInfo.pageCount = res.pages
                this.attributionPageInfo.currentPage = res.pageNum
                this.attributionPageInfo.pageSize = res.pageSize
                this.attribution_loading = false;
              });
          break;
        case 'attribution_industry':
          request.attributionIndustry(this.attributionDimension, this.attributionPageInfo.currentPage, this.attributionPageInfo.pageSize)
              .then(res => {
                this.attributionData = res.data;
                this.attributionPageInfo.pageCount = res.pages
                this.attributionPageInfo.currentPage = res.pageNum
                this.attributionPageInfo.pageSize = res.pageSize
                this.attribution_loading = false;
              });
          break;
        case 'attribution_industry_all':
          request.attributionIndustryAll(this.attributionPageInfo.currentPage, this.attributionPageInfo.pageSize)
              .then(res => {
                this.attributionData = res.data;
                this.attributionPageInfo.pageCount = res.pages
                this.attributionPageInfo.currentPage = res.pageNum
                this.attributionPageInfo.pageSize = res.pageSize
                this.attribution_loading = false;
              });
          break;
        default:
          this.attribution_loading = false;
      }
    },
    async riskExposureDateChange(){
      this.riskExposureData = await request.riskExposures(this.riskExposureDate, this.getProductInfo());
    },
    async productChange(){
      this.accountDivideOpt = ''
      this.accountDivideOptions = await request.divides(this.accountProductOpt)
      // console.log(this.productOpt)
      // this.loadAccountData(false)
    },
    benchmarkChange(){
      // console.log(this.productOpt)
      // this.loadAccountData(false)
    }
  },
  mounted() {
    document.title = '一刀智投收益可视化平台'
    request.checkLogin().then( ()=> {
      this.isLogin = true
      this.loadAccountData(true);
      request.strategyList().then(value => {
        this.accountStrategyList = value.map(v=>{
          v.value = v.strategyName
          return v
        })
      })
      this.handleAccountType('')
      // this.handleAccountType('实盘账户')
      this.loadDisplayData();
      // this.selectDisplay();
    }).catch(() => {
      this.isLogin = false;
    })
  },
  data() {
    return {
      accountInputDialog: false,
      statisticRange: {
        start: '',
        end: ''
      },
      headerTitle: '账户信息',
      chartTitle: '净值图',
      accountType: '',
      accountMobile: '',
      accountStrategy: '',
      accountMobileList: [],
      showAccountInfo: false,
      accountStrategyList: [
        {
          classId: '',
          strategyId: '',
          strategyName: ''
        }
      ],
      currentStrategy: '',
      incomeStatisticData: {
        name: '',
        startDate: '',
        nowDate: '',
        totalReturnRate: 0,
        yearReturnRate: 0,
        maxDrawdownRate: 0,
        yearFlowRate: 0,
        yearSharpRate: 0,
        kamaRate: 0
      },
      username: '',
      dateScope: 'all',
      form: {
        phone: '',
        code: ''
      },
      isLogin: false,
      timerId: '',
      isTimer: false,
      timer: 60,
      option: {
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            let html = params[0].name + "<br>";
            for (let i = params.length - 1; i >= 0; i--) {
              const value = (params[i].value).toFixed(2);
              html += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + params[i].color + ';"></span>'
              html += params[i].seriesName + ": " + value + "<br>";
            }
            return html;
          }
        },
        legend: {
          data: []
        },
        dataZoom: [
          {
            type: 'slider',
            xAxisIndex: 0,
            filterMode: 'none'
          }
        ],
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            // saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value',
          min: 0.8,
          axisLabel: {
            show: true,
            interval: 'auto',
            symbol: 'none',
            formatter: (value) => {
              return `${(value).toFixed(2)}`
            }
          },
          show: true
        },
        series: []
      },
      displayTableOpt: [{
        value: 'position',
        label: '持仓信息'
      }, {
        value: 'trade',
        label: '交易信息'
      }, {
        value: '业绩归因',
        label: '业绩归因'
      }],
      selectedDisplayTable: 'position',
      attributionTableOpt: [
        {
          value: 'attribution_date',
          label: '业绩归因（按日期汇总）'
        }, {
          value: 'attribution_industry',
          label: '业绩归因（按行业汇总）'
        }, {
          value: 'attribution_industry_all',
          label: '业绩归因（按行业汇总_所有）'
        }
      ],
      selectedAttributionTable: '',
      attribution_loading: false,
      attributionData: [],
      attributionPageInfo: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
        pageCount: 0,
      },
      dimension: 'day',
      attributionDimension: 'day',
      tableOneData: [],
      descriptiveStatisticsData: [],
      positionsData: [],
      riskExposureData: [],
      benchmarkOptions: [
        {
          label: "上证指数",
          value: "上证指数"
        },
        {
          label: "中证500",
          value: "中证500"
        },
        {
          label: "沪深300",
          value: "沪深300"
        },
        {
          label: "中证50",
          value: "中证50"
        }
      ],
      benchmarkOpt: ['中证500'],
      accountProductOptions: [],
      accountProductOpt: '',
      accountDivideOptions: [],
      accountDivideOpt: [],
      tradeData: [],
      displayDate: new Date().Format("yyyy-MM-dd"),
      riskExposureDate: new Date().Format("yyyy-MM-dd")
    };
  },
}
</script>

<style scoped>
.header {
  /* width: 98vw; */
  margin: 1vw auto;
  padding: 0;
  border: 1px solid #337ab7;
}

.account-title {
  background-color: #337ab7;
  color: #fff;
  height: 4vh;
  padding-left: 20px;
  padding-top: 10px;
}

.account-form {
  margin: 1vh 1vh;
  height: 5vh;
}

.account-info {
  /* height: 10vh; */
  font-size: 0.8vw;
}

.account-info-item {
  display: inline-block;
  width: 200px;
  margin: 10px 15px;
  vertical-align: text-top;
}

.account-info-btn {
  margin: 1vh 1vw;
}

.main {
  margin: 1vw auto;
  padding: 0;
  border: 1px solid #337ab7;
}

.income-title {
  background-color: #337ab7;
  color: #fff;
  height: 4vh;
  padding-left: 20px;
  padding-top: 10px;
}

.income-chart-content {
  height: 45vh;
  padding-bottom: 8vh;
}


.income-chart-content2 {
  height: 45vh;
  padding-bottom: 8vh;
}

.table-one{
  margin: 1vw auto;
  padding: 0;
  border: 1px solid #337ab7;
  /*height: 15vh;*/
}

.income-chart-toolbar {
  font-size: 14px;
  padding-left: 1vw;
  margin-top: 1vh;
  padding-bottom: 1vh;
}

.income-list-btn {
  margin-left: 2vw;
  display: inline-block;
}

.echarts {
  width: 100%;
  height: 96%;
}

/deep/ .el-table tr:hover>td {
  cursor:pointer
}

</style>
