import axios from 'axios'
import {Message} from "element-ui";

const service = axios.create({
	baseURL: '', // url = base url + request url
	timeout: 600000 // request timeout
})

// request interceptor
service.interceptors.request.use(
	config => {
		// do something before request is sent

		// if (store.getters.token) {
		//   // let each request carry token
		//   // ['X-Token'] is a custom headers key
		//   // please modify it according to the actual situation
		//   config.headers['X-Token'] = getToken()
		// }
		config.withCredentials = true // 参考https://developer.mozilla.org/zh-CN/docs/Web/API/XMLHttpRequest/withCredentials
		config.xsrfCookieName = 'csrftoken'
		config.xsrfHeaderName = 'X-CSRFToken'
		const regex = /.*csrftoken=([^;.]*).*$/ // 用于从cookie中匹配 csrftoken值
		config.headers['X-CSRFToken'] = document.cookie.match(regex) === null ? null : document.cookie.match(regex)[1]

		return config
	},
	error => {
		// do something with request error
		console.log(error) // for debug
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	/**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

	/**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
	response => {
		const res = response.data
		// console.log(res)
		if (!res) {
			alert(res.msg || '网络错误')
			return null;
		}
		if (res.status_code){
			return res;
		}
		const code = Number.parseInt(res.code);
		// if the custom code is not 20000, it is judged as an error.
		if (code === 10){
			Message.error(res.msg || 'Error')
			window.location = "/";
			return Promise.reject(res.msg);
		}
		if (code !== 20000) {
			Message.error(res.msg || 'Error')
			return Promise.reject(res.msg);
		}
		return res.data;
		//   // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
		//   if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
		//     // to re-login
		//     MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
		//       confirmButtonText: 'Re-Login',
		//       cancelButtonText: 'Cancel',
		//       type: 'warning'
		//     }).then(() => {
		//       store.dispatch('user/resetToken').then(() => {
		//         location.reload()
		//       })
		//     })
		//   }
		//   return Promise.reject(new Error(res.message || 'Error'))
		// } else {
		//   return res
		// }
	},
	error => {
		console.log('err' + error) // for debug
		Message.error('网络错误' + error.message);
		return Promise.reject(error)
	}
)

export default service
