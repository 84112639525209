import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import router from './router'
import ECharts from "vue-echarts"
import { use } from 'echarts/core'
import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  BarChart,LineChart
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  ToolboxComponent,
} from 'echarts/components'

use([
  LineChart,
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent, LegendComponent, DataZoomComponent, GridComponent, ToolboxComponent
]);
Vue.component('v-chart', ECharts)
Vue.config.productionTip = false


Date.prototype.Format = function (fmt) {
  const o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    "S": this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


