import request from '@/utils/http'

/**
 * 检查验证码
 * @param {String} phoneNumber 手机号
 * @param {String} smscode 验证码
 */
export function ckSmsCode(phoneNumber, smscode) {
	const url = '/v3/verifysmscode'
	const data = {
		nativeCode: 'imAinvestcnabc',
		phoneNumber: phoneNumber,
		smsCode: smscode
	}
	return request({
		url: url,
		method: 'post',
		data
	})
}


/**
 * sms code
 * @param {*} phoneNumber
 */
export function getSmsCode(phoneNumber) {
	const url = '/v3/smscode'
	const data = {
		nativeKey: 'imAinvestcnabc',
		phoneNumber: phoneNumber
	}
	return request({
		url: url,
		method: 'post',
		data
	})
}


/**
 * 检测手机号是否占用
 * @param {String} phoneNumber
 */
export function usedphonenumber(phoneNumber) {
	const url = '/v3/usedphonenumber'
	const data = {
		nativeCode: 'imAinvestcnabc',
		phoneNumber: phoneNumber
	}
	return request({
		url: url,
		method: 'post',
		data
	})
}

export function login(username,password) {
	const url = '/v3/data_login'
	return request({
		url: url,
		method: 'post',
		data:{
			username,password
		}
	})
}

export function logout() {
	const url = '/v3/data_logout'
	return request({
		url: url,
		method: 'post'
	})
}
export function checkLogin() {
	const url = '/v3/data_check_login'
	return request({
		url: url,
		method: 'get'
	})
}

export function incomeVisual(username, scope, start, end, dimension, isMooc) {
	const url = '/v3/income_visual'
	const data = {
		username, scope, isMooc, start, end, dimension
	};
	return request({
		url: url,
		method: 'post',
		data
	})
}

export function incomeStatistic(username,start,end,scope,isMooc) {
	const url = '/v3/income_statistic'
	const data = {
		username,
		start,
		end,
		scope,
		isMooc
	}
	return request({
		url: url,
		method: 'post',
		data
	})
}

export function strategyStatistic(strategyId,classId,start,end,scope) {
	const url = `/v3/strategy_statistic?strategyId=${strategyId}&classId=${classId}&scope=${scope}&start=${start}&end=${end}`
	return request({
		url: url,
		method: 'get'
	})
}

export function productStatistic(data) {
	return request({
		url: `/v3/product_statistic`,
		method: 'post',
		data
	})
}


export function loginv2(phone, code) {
	const data = {
		phone_number: phone,
		verify_code: code
	}
	return request({
		url: '/api/ainvest_login_new/',
		method: 'post',
		data
	})
}

export function loginsmscode(phoneNumber) {
	const url = '/v3/loginsmscode'
	const data = {
		nativekey: 'imAinvestcnabc',
		phonenumber: phoneNumber
	}
	return request({
		url: url,
		method: 'post',
		data
	})
}

export function realAccountStrategies() {
	const url = '/v3/real_account_strategies'
	return request({
		url: url,
		method: 'get',
		data: {}
	})
}

export function accountList(type) {
	const url = `/v3/account_list?accountType=${type}`;
	return request({
		url: url,
		method: 'get',
	})
}

export function strategyList() {
	const url = '/v3/visual_strategy_list';
	return request({
		url: url,
		method: 'get',
	})
}

export function strategyInfo(strategyId, classId, scope, start, end, dimension) {
	const url = `/v3/strategy_info?strategyId=${strategyId}&classId=${classId}&scope=${scope}&start=${start}&end=${end}&dimension=${dimension}`;
	return request({
		url: url,
		method: 'get',
	})
}

export function productInfo(data) {
	return request({
		url: `/v3/product_info`,
		method: 'post',
		data
	})
}


export function riskExposures(date, productInfo) {
	return request({
		url: `/v3/risk_exposure/${date}`,
		method: 'post',
		data: productInfo
	})
}


export function positions(date, productInfo) {
	return request({
		url: `/v3/position/${date}`,
		method: 'post',
		data: productInfo
	})
}

export function trades(date, productInfo) {
	return request({
		url: `/v3/trade/${date}`,
		method: 'post',
		data: productInfo
	})
}

export function products() {
	const url = `/v3/products`
	return request({
		url: url,
		method: 'get',
		data: {}
	})
}

export function divides(productName) {
	const url = `/v3/divides?productName=${productName}`;
	return request({
		url: url,
		method: 'get',
		data: {}
	})
}


export function attributionDate(pageNum,pageSize) {
	const url = `/v3/attribution_date`;
	return request({
		url: url,
		method: 'post',
		data: {
			pageNum,pageSize
		}
	})
}

export function attributionIndustry(frequency,pageNum,pageSize) {
	const url = `/v3/attribution_industry?frequency=${frequency}`;
	return request({
		url: url,
		method: 'post',
		data: {
			pageNum,pageSize
		}
	})
}

export function attributionIndustryAll(pageNum,pageSize) {
	const url = `/v3/attribution_industry_all`;
	return request({
		url: url,
		method: 'post',
		data: {
			pageNum,pageSize
		}
	})
}


